import React from 'react'

function Footer() {
  return (
    <>
      <div className="bg-white">
        <div className="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
          <div className="flex justify-between w-full mt-8 md:mt-0 md:order-1">
            <p className="text-base leading-6 text-center text-gray-400">
              &#xA9; Uni_Link Software and Automation LLC
            </p>
            <p className="mb-2 text-base leading-6 text-gray-600">
              developed with support from
              <img
                src="https://res.cloudinary.com/dhcx3vzmg/image/upload/v1611851382/iyzasenyr5lmgokck8fm.jpg"
                alt="FIT logo"
                className="h-6"
              />
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
